function SubmitButton() {
  return (
    <button
      type='submit'
      className='form__button form__button_type_search'
      value=''
    />
  );
}

export default SubmitButton;
